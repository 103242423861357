/* Your custom CSS file */
.custom-modal-close .ant-modal-close {
    background-color:#1B4F72; /* Change to your desired color */
    border-radius: 50%; /* Optional: makes the background circular */
    padding: 5px; /* Optional: adds some padding inside the icon */
  }
  
  .custom-modal-close .ant-modal-close-x {
    color: white; /* Change the icon color if needed */
  }
  /* .custom-modal-close .ant-modal-content  {
    background-color:#1B4F72; 
    color: white;

  } */