.features_col{
    width: 20%;
    margin-left: 4%;
}

.features_col:hover{
    transform: scale(1.05);
    /* transition: 0.1s; */
}
@media screen and (max-width: 1100px) {
    .features_col{
        width: 45%;
        margin: 4%;
    }  
}
@media screen and (max-width: 700px) {
    .features_col{
        width: 100%;
        margin: 4%;
    }  
}