th {
    background-color: #1B4F72;
    border: 1px solid black !important;
    color: white !important;
    font-size: 15px;
    font-weight: 500;
}

table {
    width: 100%;
    font-family: sans-serif;
}

td {
    border: 1px solid black;
    text-align: center;
    font-size: 13px;
    padding: 1px;
    color: black;
    font-family: sans-serif;
}

#my-table {
    border-collapse: collapse;
}

@media print {
    td {
        border: 1px solid gray !important
    }

    th {
        border: 1px solid gray !important
    }
}

#table_data {
    border: none !important;
    text-align: left !important;
    font-size: 13px;
    font-family: sans-serif;
}

#table_border {
    border: 1px solid gray !important;
    font-size: 13px !important;
    padding: 10px !important;

}


.print-footer {
    display: none;
}
@media screen {
    .print_components{
      display: none;
    }
  }

  
@media print {
    .print-container {
        position: relative;
    }

    .print-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        padding: 10px;
        background-color: white;
        display: block;
    }


    #col_visible {
        display: none;
    }

    td {
        border: 1px solid black;
        text-align: center;
        font-size: 5px;
        padding: 1px;
        color: black;
        font-family: sans-serif;
    }
    .print_components{
        display: block;
      }
}