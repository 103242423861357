.main {
  margin-left: 80px;
  margin-top:20px;
  display: flex;
  gap: 20px;
}
.ICON {
  background-color: #1B4F72;
  border-radius: 100%;
  padding: 10px;
  color: white;

}
.custom-image .ICON {
  height: 15vh;
  width: 100%;
  display: block;
  border-radius: 20px;
  margin: auto;
}
.custom-card p {
  color: rgb(35, 33, 33);
}
.card:hover {
  /* background-color:rgb(239, 107, 6); */
  transform: scale(1.05);
  transition: 0.3s;
}
h4{
    text-align: center;
}
.card{
    background-color: white;
}
