.chart_card{
    background-color: rgb(230, 233, 236);
    margin: 10px;
}
.centered-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.dashboard_col {
    overflow: hidden;
    text-align: center;
    margin: 3px;
}


.dashboard_card:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
  .trip_chart{
    margin-left: 5%;
  }
.ChartContainer{
    width: 100vh;
    height: 50vh;
    background-color: brown;
}
.dashboard_card{
    width: 16%;
}
@media screen and (max-width: 1100px) {
    .dashboard_main_Row {
        padding: 0px;
    }
   

}

@media screen and (max-width: 900px) {

.dashboard_main {
  margin-left: 6%;

}
.dashboard_card{
    width: 30%;
    margin-bottom: 2%;
}
}


@media screen and (max-width: 800px) {
    .dashboard_main {
        margin-left: 6%;
      
      }
      .trip_chart{
        margin-left: 0%;
      }
      .dashboard_card{
        width: 49%;
        margin-bottom: 2%;
    }
}

@media screen and (max-width: 500px) {

    .dashboard_main {
        margin-left: 0%;
      
      }
      
}

@media screen and (max-width: 400px) {
   
    .dashboard_main {
        margin-left: 3%;
      
      }
}

