.contact_form_field {
    background-color: #f8f8f8;
    color: black;
    width: 30%;
    margin: 1%;
    height: 30px;
    border: solid 1px black;
}

.contact_form_field_text_area {
    background-color: #f8f8f8;
    color: black;
    width: 63%;
    margin: 1%;
    height: 30px;
}

.main_contact_heading {
    color: black;
    font-size: xx-large;
    /* text-shadow: 2px 2px 4px #000000; */
    text-align: center;
}

.contact_form_container {
    text-align: center;
}
.send_button{
    height: 50px;
    width: 200px;
    background-color: black;
    color: white;
    margin: 2px;
    font-weight: 800;
    border: 2px solid white;
}
.send_button:hover{
    background-color: yellow;
    color: black;
}
.social_icons:hover{
    transform: scale(1.05);
}
.social_icons_size{
    color: white;
    font-size: 30px;
}
@media screen and (max-width: 700px) {
    .contact_form_field {
        background-color: #f8f8f8;
        color: black;
        width: 90%;
        margin: 1%;
        height: 30px;
    }
    
    .contact_form_field_text_area {
        background-color: #f8f8f8;
        color: black;
        width: 90%;
        margin: 1%;
        height: 30px;
    }
    
    .main_contact_heading {
        color: yellow;
        font-size: x-large;
        /* text-: 2px 2px 4px #000000; */
        text-align: center;
    }
    .contact_class{
        margin: 20px;
    }
    .social_icons_size{
        color: white;
        font-size: 15px;
    }
}