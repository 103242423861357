.web_intro_container {
  position: relative;
}
@import url('https://fonts.googleapis.com/css2?family=Artifika&display=swap');
.web_animate{
  font-size: xx-large;
  text-align: center;
  color: hsl(39, 100%, 50%);
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;
}
.web_intro_img {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.web_intro {
  margin: auto;
  opacity: 0.8;
  background-color: black;
  height: 100vh;
  width: 100%;
  padding-top: 100px;
}

#web_heading {
  color: white;
  font-size: 40px;
  margin-top: 100px;
  text-align: center;
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;

}

.web_para {
  color: white;
  font-size: larger;
}

#web_sub_heading {
  color: #f5b921;
  font-size: xx-large;
}

.web_intro_btn {
  height: 50px;
  width: 200px;
  background-color: black;
  color: white;
  margin: 2px;
  font-weight: 800;
  border: 2px solid white;
}

.web_intro_btn:hover {
  background-color: yellow;
  color: black;
}

.animate__animated.animate__slideInLeft {
  --animate-duration: 2s;
  animation-iteration-count: 2;
}

.animate__animated.animate__slideInRight {
  --animate-duration: 2s;
  animation-iteration-count: 2;
}

@media screen and (max-width: 1100px) {
  .web_intro_container {
    background-color: black;
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
    height: 600px;
  }

  .web_intro {
    opacity: 0.8;
    background-color: black;
    height: 98%;
    width: 92%;
    padding-left: 80px;
    padding-top: 10px;
    /* margin: auto; */
  }

  .web_intro_img {
    position: absolute;
    width: 100%;
    height: 100%;

  }
}

@media screen and (max-width: 960px) {
  
#web_heading {
  color: white;
  font-size: 25px;
  margin-top: 50%;
  text-align: center;
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;
  margin-left: 10px;
  margin-right: 10px;

}
@import url('https://fonts.googleapis.com/css2?family=Artifika&display=swap');
.web_animate{
  font-size: 20px;
  text-align: center;
  color: hsl(39, 100%, 50%);
  font-family: "Artifika", serif;
  font-weight: 400;
  font-style: normal;
  margin-top: 10%;
}
  .web_intro_container {
    background-color: black;
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
    height: 600px;
  }

  .web_intro {
    opacity: 0.8;
    background-color: black;
    height: 98%;
    width: 90%;
    padding-left: 80px;
    padding-top: 10px;
    /* margin: auto; */
  }

  .web_intro_img {
    position: absolute;
    width: 100%;
    height: 100%;

  }
}

@media screen and (max-width: 700px) {
  .web_intro_container {
    background-color: black;
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
    height: 600px;
    text-align: center;
  }

  .web_intro {
    opacity: 0.8;
    background-color: black;
    height: 98%;
    width: 100%;
    padding-left: 0px;

    /* margin: auto; */
  }

  .web_intro_img {
    position: absolute;
    width: 100%;
    height: 100%;

  }
}